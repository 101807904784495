import React, { useState, useEffect } from "react"
import { Routes, Route } from "react-router-dom"
import { useAuth } from "./context/AuthContext"
import AuthForm from "./components/AuthForm"
import Dashboard from "./components/Dashboard"
import './styles/App.scss';

function App() {
  const [appLoaded, setAppLoaded] = useState(false)
  const [authenticationCheck, setAuthenticationCheck] = useState(false)
  const { isAuthenticated, checkAuthentication } = useAuth()

  useEffect(() => {
    checkAuthentication().then(() => {
      setAuthenticationCheck(true)
      if (isAuthenticated) {
        setAppLoaded(true)
      }
    })
  }, [checkAuthentication, isAuthenticated])

  return (
    <main className="App">
      {authenticationCheck ? (
        <Routes>
          <Route path="/" element={(isAuthenticated && appLoaded) ? <Dashboard /> : <AuthForm />} />
        </Routes>
      ) : (
        <section />
      )}   
    </main>
  );
}

export default App;
