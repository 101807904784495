import React, { useState } from 'react'
import { useAuth } from "../context/AuthContext"
import DataTable from "./DataTable"
import Modal from './Modal'
import deleteMeetingData from '../hooks/deleteMeeting'
import '../styles/coaching-hours.scss'

function CoachingHours(){ 
    const { loadData } = useAuth()
    const [selectedRows, setSelectedRows] = useState([])
    const [modal, setModal] = useState(false)

    //Write a try block to delete meetings 
    const deleteMeetings = () => {
        deleteMeetingData(selectedRows)
        setSelectedRows([])
        //Reset checkboxes in DOM
        const checkboxes = document.querySelectorAll('input[type="checkbox"]')
        checkboxes.forEach( checkbox => checkbox.checked = false)
        loadData()
    }

    return(
        <section id='coaching-hours'>
            {modal &&
                <Modal 
                    setModal={setModal} 
                    selectedRows={ selectedRows.length === 1 && selectedRows} 
                    setSelectedRows={setSelectedRows}
                />
            } 

            <div id='button-container'>
                {selectedRows.length < 1 ? (
                     <button className='btn--primary' onClick={() => setModal(!modal)}>Add Hours</button>
                ) : (
                    <div className="flex-row flex-row--space">
                        {selectedRows.length === 1 && <button className='btn--edit' onClick={() => setModal(!modal)}>Edit Row</button>}
                        <button className='btn--delete' onClick={() => deleteMeetings()}>{ selectedRows.length > 1 ? "Delete Rows" : "Delete Row"}</button>
                    </div>
                )}  
            </div>
            <DataTable 
                selectedRows={selectedRows}
                setSelectedRows={setSelectedRows}
            />
        </section>
    )
}

export default CoachingHours