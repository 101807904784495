import HoursForm from './HoursForm'
import exitIcon from '../assets/exit.svg'
import '../styles/modal.scss'


function AddHoursModal({ setModal, selectedRows, setSelectedRows }){
    //selectedRows will only be present when user is trying to EDIT a record -- otherwise the modal will default to ADD hours 
    return(
        <section className="modal-background flex-row flex-row--center">
            <div className='modal-inner dashboard flex-column'>
                <img onClick={() => setModal(false)} className="exit icon icon--md" src={exitIcon} alt="Exit Add Hours Modal" />
                <div className='flex-row flex-row--space'>
                    <h2>{selectedRows ? "Edit " : "Add "} EIR Hours</h2>
                </div>
                <HoursForm 
                    setModal={setModal} 
                    selectedRows={selectedRows}
                    setSelectedRows={setSelectedRows}
                /> 
            </div>
        </section>
    )
}

export default AddHoursModal