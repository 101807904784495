import { useMemo, useEffect } from 'react'
import '../styles/reports.scss'

function ReportsTable({ tableName, data, currentMonth, hourType, setTotal }) {
    const filteredHours = useMemo(() => {
        //Filter data by month 
        const filteredData = data?.filter(item => {
            const dateMonth = new Date(item.date + ' PST').getMonth()
            const isMatchingMonth = dateMonth === Number(currentMonth)
            const isCoachingMeeting = hourType.includes(item.supportType)
            return isMatchingMonth && isCoachingMeeting
        })
        // Sort the filtered data by date
        filteredData?.sort((a, b) => new Date(a.date) - new Date(b.date));
        return filteredData;
    }, [data, currentMonth, hourType])

    //Send total data to Reports Component 
    useEffect(() => {
        setTotal && setTotal(filteredHours?.reduce((acc, item) => acc + item.duration, 0))
    }, [filteredHours, setTotal])
    
    return(
        <table id={`${tableName}-table`}>
            <thead>
                <tr>
                    <th className='column--first'>Company Name</th>
                    <th className="column--second">Duration</th>
                    <th className="column--second">Date</th>
                </tr>
            </thead>
            <tbody>
                {
                    filteredHours?.map( (row, i) => { 
                        return(
                            <tr key={i}>
                                <td className="column--first">{row.companyName}</td>
                                <td className="column--second">{row.duration}</td>
                                <td className="column--second">{row.date}</td>
                            </tr>
                        )
                    })
                }
            </tbody>
        </table>
    )
}

export default ReportsTable