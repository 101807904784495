import { useState, useEffect } from 'react'
import { useAuth } from "../context/AuthContext"
import addMeetingData from '../hooks/addMeetingData'
import editMeetingData from '../hooks/editMeetingData'
import '../styles/hour-form.scss'

function HoursForm({ setModal, selectedRows, setSelectedRows }) {
    const { companyList, email, loadData } = useAuth()
    const [companyName, setCompanyName] = useState('')
    const [date, setDate] = useState('')
    const [duration, setDuration] = useState('')
    const [meetingType, setMeetingType] = useState('')
    const [notes, setNotes] = useState('')
    const [altName, setAltName] = useState('')
    const [button, setButton] = useState('')
    
    const handleSubmit = (e) => {
        e.preventDefault()
        const meetingData = {
            companyName: companyName ? companyName : 'recXDawIdqNNa4RFG', // text is record ID for Foresight -- required in Airtable
            altName: altName,
            supportType: meetingType,
            date: date,
            email: email,
            duration: Number(duration),
            notes: notes,
            recordID: selectedRows ? selectedRows[0].recordID : '',
        }

        if(button === 'submit'){ 
            addMeetingData([meetingData])
            setModal(false)
        } else if (button === 'save'){
            editMeetingData(meetingData)
            setSelectedRows([])
            const checkboxes = document.querySelectorAll('input[type="checkbox"]')
            checkboxes.forEach( checkbox => checkbox.checked = false)
            setModal(false)
        } else{ 
            addMeetingData([meetingData]) //if user selects "add another"
        }

        //reset states
        setCompanyName('')
        setDate('')
        setDuration('')
        setNotes('')
        setAltName('')
        loadData()
    }

    //If editing a records, set state to the record data
    useEffect(() => { 
        if (selectedRows) { 
            const row = selectedRows[0]
            setCompanyName(row.companyID)
            setDate(row.date)
            setDuration(row.duration)
            setMeetingType(row.supportType)
            setAltName(row.altName)
            setNotes(row.notes)
        }
    }, [selectedRows])



    return(
        <form onSubmit={handleSubmit}>
            <div className="inner-form">
                <label htmlFor="company-name">Support Type</label>
                <select 
                    name="meeting-type"
                    id="meeting-type"
                    value={meetingType}
                    onChange={(e) => setMeetingType(e.target.value)}
                    required
                >
                    <option value="" selected="selected">Select Support Type</option>
                    <option value="Supporting a company">Supporting a company</option>
                    <option value="Advisory Board Meeting">Advisory Board Meeting</option>
                    <option value="Program Moderation">Program Moderation</option> 
                    <option value="Content Development">Content Development</option>
                    <option value="Intake">Intake</option>
                    <option value="Other">Other</option>
                </select>

                { 
                    (meetingType === "Supporting a company" || meetingType === "Advisory Board Meeting") && 
                    <div>
                        <label htmlFor="company-name">Company Name</label>
                        <select 
                            name="company-name" 
                            id="company-name" 
                            value={companyName}
                            onChange={(e) => setCompanyName(e.target.value)}
                            required
                        >
                            <option value="" disabled selected>Select Company</option>
                            {
                                companyList && companyList.map( (company, i) => { 
                                    return(
                                        <option key={i} value={company.id}>{company.name}</option>
                                    )
                                })
    
                            }
                    
                        </select>
                    </div>
                }

                {
                    //option values are AirTable record ids -- this is required to store the data
                    meetingType === "Program Moderation" && 
                    <div>
                        <label htmlFor="program-name">Program</label>
                        <select 
                            name="program-name" 
                            id="program-name" 
                            value={altName}
                            onChange={(e) => setAltName(e.target.value)}
                            required
                        >
                            <option value="" disabled selected>Select Program</option>
                            <option value="Launch">Launch</option>
                            <option value="Deliver">Deliver</option>
                            <option value="Investor Readiness">Investor Readiness</option>
                            <option value="Other">Other</option>
                        </select>
                    </div>
                }

                {
                    meetingType === "Intake" &&
                    <div> 
                        <label htmlFor="intake-name">Company Name</label>
                        <input
                            name="intake-name" 
                            id="intake-name" 
                            value={altName}
                            onChange={(e) => setAltName(e.target.value)}
                            required
                        />
                    </div>
                }

                {
                    meetingType === "Content Development" &&
                    <div> 
                        <label htmlFor="project-name">Project Name</label>
                        <input
                            name="project-name" 
                            id="project-name" 
                            value={altName}
                            onChange={(e) => setAltName(e.target.value)}
                            required
                        />
                    </div>
                }

                <div className='flex-row'>
                    <div>
                        <label htmlFor="date">Date</label>
                        <input 
                            id="date" 
                            type="date"
                            value={date}
                            onChange={(e) => setDate(e.target.value)} 
                            required
                        />
                    </div>
                 
                    <div>
                        <label htmlFor="duration">Duration (Hrs)</label>
                        <input 
                            type="number" 
                            name="duration" 
                            id="duration"
                            min="0"
                            value={duration}
                            onChange={(e) => setDuration(e.target.value)}  
                            required
                        />
                    </div>
                </div>
                
                <label htmlFor="notes">Notes</label>
                <textarea 
                    name="notes" 
                    id="form-notes" 
                    value={notes}
                    onChange={(e) => setNotes(e.target.value)}  
                    required
                >
                </textarea>
                {selectedRows ? (
                    <button id="save" className='btn btn--primary' onClick={(e) => setButton(e.target.id)}>Save</button>
                ) : (
                    <div>
                        <button id="submit" className='btn btn--primary' onClick={(e) => setButton(e.target.id)}>Submit</button>
                        <button className="btn btn--secondary" onClick={(e) => setButton(e.target.id)}>Add Another Meeting</button>
                    </div>
                )} 
            </div>
        </form>
    )
}

export default HoursForm