import React, { useState, useMemo, useEffect } from 'react'
import { useAuth } from "../context/AuthContext"
import ReactPaginate from 'react-paginate'
import TableHeader from "./TableHeader"
import '../styles/data-table.scss'

function DataTable({ selectedRows, setSelectedRows }){
    const { meetingData } = useAuth()
    const [rows, setRows] = useState([])
    const [filterItems, setFilterItems] = useState([])
    const [pageNumber, setPageNumber] = useState(0)
    const itemsPerPage = 15
    
    const columnNames = [
        {id: "companyName", label:"Company Name"}, 
        {id: "date", label:"Date"}, 
        {id: "duration", label: "Duration"},
        {id: "supportType", label: "Support Type"},
        {id: "notes", label:"Notes"}
    ]

    useEffect(() => {
        setRows(meetingData)
    },[meetingData])

    //Paginate meeting rows while filtering rows (if necessary)
    const filteredRows = useMemo(() => {
        const startIndex = pageNumber * itemsPerPage
        const endIndex = startIndex + itemsPerPage
        const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']
        //Convert month string to number-string in array
        const convertedFilterItems =  filterItems.map( item => { 
            const monthIndex = months.indexOf(item)
            if (monthIndex !== -1) {
                const monthNumber = monthIndex + 1
                return monthNumber.toString().padStart(2, '0')
            } else {
                return item
            }  
        })
        //Filter rows based on convertedFilteredItems 
        return rows
          ?.filter((item) => {
            const monthPart = item.date?.slice(5, 7)
            const hasMonth = convertedFilterItems.includes(monthPart)
            const hasMatchingValue =  Object.values(item).some((val) => filterItems.includes(val))
            return !hasMonth && !hasMatchingValue
          })
          ?.slice(startIndex, endIndex)

    }, [rows, filterItems, pageNumber])


    //Advances the page in paginated component
    const handlePageChange = ({ selected }) => { 
        setPageNumber(selected)
    }

    //Adds rows to selectedRows state -- relevant to delete function
    const addCheckboxToState = (row) => {
        if (selectedRows.some(item => item.recordID === row.recordID)) {
          setSelectedRows(selectedRows.filter(item => item.recordID !== row.recordID));
        } else {
          setSelectedRows([...selectedRows, row]);
        }
    }
      
    return(
        <>
            <table>
                <thead>
                    <tr>
                        <th></th> 
                        {columnNames.map( (col, key) => {
                            return(
                                <TableHeader 
                                    key={key}
                                    id={col.id}
                                    label={col.label}
                                    rows={rows}
                                    setRows={setRows}
                                    filterItems={filterItems}
                                    setFilterItems={setFilterItems}
                                />
                            )
                        })}
                    </tr>
                </thead>
                {rows ? (
                    <tbody>
                        {   
                            filteredRows?.map((row, id) => {
                                return(
                                    <tr key={id}>
                                        <td>
                                            <input 
                                                type="checkbox" 
                                                name={id}
                                                onChange={() => addCheckboxToState(row)}
                                            />
                                        </td>
                                        <td className="companyName">{row.altName ? row.altName : row.companyName}</td>
                                        <td className="date">{row.date}</td>
                                        <td className="duration">{row.duration}</td>
                                        <td>{row.supportType}</td>
                                        <td>{row.notes}</td>
                                    </tr>
                                )
                            })
                        }
                    </tbody> 
                ) : (
                    <h3>Loading data...</h3>
                )}
            </table>
            <ReactPaginate
                pageCount={Math.ceil(rows?.length / itemsPerPage)}
                onPageChange={handlePageChange}
                containerClassName={'pagination'}
                activeClassName={'active'}
                pageClassName={'page-item'}
                previousClassName={'page-item'}
                nextClassName={'page-item'}
                pageLinkClassName={'page-link'}
                previousLinkClassName={'page-link'}
                nextLinkClassName={'page-link'}
                breakClassName={'page-item'}
                breakLinkClassName={'page-link'}
            />
        </>
    )
}

export default DataTable