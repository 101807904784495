const admin = 'tmaksymiw@foresightcac.com'
const accounting = 'accounting@foresightcac.com'

const faq = [
    {
        faq: "How to use the Meeting Tracker",
        content: [
            {
                title:'',
                text:`
                    The Meeting Tracker is a platform that allows you to track and report your time supporting Foresight ventures and programs.
                `   
            },
            {
                title:'',
                text:`
                    To submit an hour you can select the ‘Add Hours’ button to bring up the form and input the support detail. 
                    If you need more details on the available options please review the ‘What is Billable’ section of this FAQ.
                `
            },
            {
                title:'',
                text:`
                    In the event that you’ve made a mistake while entering your hours you have the option to edit or delete line items. 
                    Check the box you want to update and select edit or delete. Please note, once a meeting is deleted it cannot be undone. 
                `
            },
            {
                title:'',
                text:`
                    The Meeting Tracker also provides monthly reports found in the ‘Reports’ tab. From this menu you can select the month you’re 
                    interested in and export this data as a PDF. It is encouraged that you send this PDF with your invoice each month. 
                `
            },
            {
                title:'',
                text:`
                    If you have any questions about the Meeting Tracker, please contact ${admin}
                `
            }
        ]
    },

    {
        faq: "What Hours are Billable?",
        content: [
            {
                title:'',
                text:`
                    As an EIR you may invoice Foresight for your time spent coaching and supporting our programs. 
                    It is your responsibility to make sure you are accurately tracking and reporting your hours. 
                    It is important to note that not all your interactions with Foresight are billable. Below is a list of billable activities:
                `
            },
            {
                title:'Supporting a company', 
                text:`
                    Coaching - This includes any time you spend working directly with a company participating in a Foresight program. 
                    If you do not see the company you worked with in the dropdown, this might suggest that this company is no longer eligible 
                    for EIR support.
                    Research & Follow Up - For each hour of coaching time you are entitled to bill up to 30 minutes of meeting preparation and follow-up time; 
                    this counts against the 6 hours budgeted to each company.
                `
            },
            {
                title:'Advisory Board Meeting',
                text:`
                    Every quarter all companies participating in Foresight’s Residency program will have an Advisory Board Meeting scheduled. 
                    If you participate in one of these meetings you may invoice for 1 hour. 
                `
            },
            {
                title:'Program Moderation',
                text:`
                    If you lead a Foresight accelerator program or participate in a program weekly check-in you are entitled to bill for your time. 
                    This does not apply to EIRs auditing a course. 
                `
            },
            {
                title:'Content Development',
                text:`
                    Foresight is regularly updating and developing new courses, programs, and content that we share across the cleantech ecosystem. 
                    If you contribute to developing any of these resources you may bill for this time.
                `
            },
            {
                title:'Intakes',
                text:`
                    Every company that joins Foresight’s programs must attend an intake meeting. 
                    If you participate in one of these meetings you may invoice for 1 hour.
                `
            },
            {
                title:'Other', 
                text:`
                    The other category captures meetings or support requested by Foresight but don’t fall into any of the other categories listed above. 
                    This may be a consultation with Foresight staff, an introduction request, etc.
                `
            },
            {
                title:'Cancellation Policy',
                text:`
                    The Foresight team has a cancellation policy to ensure that the time of our EIRs is protected. 
                    In the event that a meeting is canceled without cause and within 24 hours of the scheduled time you are entitled to bill for your time. 
                    However, if the meeting is canceled with legitimate reason (emergency, or otherwise) then the meeting will be rescheduled and is not billable. 
                `
            }
        ]
    },

    {
        faq:"How to Send an Invoice",
        content: [
            {
                title:'',
                text:`
                    Foresight has many funders that support our programs and it is important that our records are precise. 
                    For this reason we encourage you to submit your hours in the Meeting Tracker regularly.
                `
            },
            {
                title:'',
                text:`
                    At the end of each month, you will receive an email reminding you to enter any outstanding meetings into the Meeting Tracker. 
                    You may then submit an invoice to ${accounting} by the 10th of the month. 
                    Your invoice should only include the hours from the previous month (eg. coaching hours accumulated between July 1st - July 30th must be submitted by August 10th). 
                    Any tax applicable in your province may be applied. 
                `
            },
            {
                title:'',
                text:`
                    If your invoice is submitted on time it will be processed and paid out by the end of the month that it was sent. 
                    If you fail to meet the invoice deadline your invoice will be processed and paid in the following month. Please ensure that you are submit your invoices in a timely manner,  any invoices that are sent 90 days after the due date penalties may apply
                    If you have any questions about how to submit your invoice please contact ${admin} 
                `
            }
        ]
    }    
]

export default faq