import { useState, useEffect } from 'react'
import { useAuth } from '../context/AuthContext'
import exportReportPDF from '../hooks/exportReportPdf'
import ReportsTable from './ReportsTable'
import arrowIcon from '../assets/arrow.svg'
import '../styles/reports.scss'

function Reports({ setDashboard }){
    const { meetingData, username } = useAuth()
    const [rows, setRows] = useState([])
    const [currentMonth, setCurrentMonth] = useState(new Date().getMonth())
    const [coachingHourTotal, setCoachingHourTotal] = useState(0)
    const [programHourTotal, setProgramHourTotal] = useState(0)
    const monthsArr = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"]

    //Set rows state on load
    useEffect(() => {
        setRows(meetingData)
    },[meetingData])

    //Export pdf of EIR Monthly Report
    const handleExportPDF = () => {
        const  userArray = username.split(" ") 
        const fName = userArray[0]
        const lName = userArray[1]
        exportReportPDF(monthsArr[currentMonth], fName, lName)
    }
 
    return(
        <section className="report-container">
            <div className="back-to-hours flex-row" onClick={() => setDashboard(true)}>
                <img className="icon icon--md" src={arrowIcon} alt="Back to hours table" />
                <p><u>Back to Hours</u></p>
            </div>
            <div className="report-header">
                <h2>Monthly Hour Report</h2>
                <div className='flex-row gap--15'>
                    <select 
                        name="months" 
                        id="months-select"
                        defaultValue={currentMonth}
                        onChange={(e) => setCurrentMonth(e.target.value)}
                    >
                        {
                            monthsArr.map((month, i) => { 
                                return(
                                    <option key={i} value={i}>{month}</option>
                                ) 
                            })
                        }
                    </select>
                    <button id="export-pdf-btn" onClick={handleExportPDF} className="btn btn--primary">Export PDF</button>
                </div>
            </div>
            <div className='flex-column gap--3'>
                <h3>{monthsArr[currentMonth] + " Report"}</h3>
                <div>
                    <span>Coaching Hours</span>
                    <ReportsTable
                        tableName='coaching' 
                        data={rows}
                        currentMonth={currentMonth}
                        setTotal={setCoachingHourTotal}
                        hourType={["Supporting a company", "Advisory Board Meeting"]}
                    /> 
                </div>
                <div>
                    <span>Program Hours</span>
                    <ReportsTable
                        tableName='program' 
                        data={rows}
                        currentMonth={currentMonth}
                        setTotal={setProgramHourTotal}
                        hourType={["Program Moderation", "Intake", "Content Development", "Other"]}
                    /> 
                </div>

                <table id="total-table">
                    <tbody>
                        <tr>
                            <td className="row-header column--first">Coaching Total</td>
                            <td className="column--second">{coachingHourTotal}</td>
                            <td></td>
                        </tr>
                        <tr>
                            <td className="row-header column--first">Program Total</td>
                            <td className="column--second">{programHourTotal}</td>
                            <td></td>
                        </tr>
                        <tr>
                            <td className="row-header column--first">Grand Total</td>
                            <td className="column--second">{coachingHourTotal + programHourTotal}</td>
                            <td></td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </section>
    )
}

export default Reports;