import axiosInterceptor from './axiosInterceptor'

const editMeetingData = async (record) => { 
    const api = process.env.REACT_APP_BACKEND_KEY
    try{ 
        const response = await axiosInterceptor.post(`${api}/edit`, record, {
            credentials: 'include'
        })

        console.log('Response: ' + response.data)
    } catch(error){ 
        console.error(error)
    }
}

export default editMeetingData