import { useState } from 'react'
import { useAuth } from '../context/AuthContext'
import Faq from './FAQ'
import logoSecondary from "../assets/fs-logo-secondary.png"
import '../styles/main-nav.scss'

function MainNav({ setDashboard}) {
    const [faq, setFaq] = useState(false)
    const { logout } = useAuth()

    return (
        <nav id="main-nav">
            {faq && <Faq setFaq={setFaq}/>}
            <img className="logo" src={logoSecondary} alt="Foresight Logo" />
            <div className='flex-row flex-row--center dropdown-container'>
                <div className="user-dropdown flex-row flex-row--space">
                    <p onClick={() => setFaq(!faq)}>FAQ</p>
                    <p onClick={() => setDashboard(false)}>Reports</p>
                    <p onClick={() => logout()}>Logout</p>
                </div>
            </div>
        </nav>
    )
}

export default MainNav