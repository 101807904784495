import axiosInterceptor from './axiosInterceptor'

const addMeetingData = async (meetingData) => { 
    const api = process.env.REACT_APP_BACKEND_KEY
    try{ 
        const response = await axiosInterceptor.post(`${api}/add`, meetingData, {
            credentials: 'include',
        })
        console.log("Response: " + response.status)
    } catch(error){ 
        console.error(error)
    }
}

export default addMeetingData