import React, {useState} from 'react'
import MainNav from './MainNav'
import CoachingHours from './CoachingHours'
import Reports from './Reports'
import Footer from './Footer'
import '../styles/dashboard.scss'

function Dashboard(){ 
    const [dashboard, setDashboard] = useState(true)

    return(
        <section id='dashboard'>
            <MainNav setDashboard={setDashboard}/>
            { 
                dashboard
                    ? <CoachingHours /> 
                    : <Reports setDashboard={setDashboard}/> 
            }
            <Footer />
        </section>
    )
}

export default Dashboard