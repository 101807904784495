import axios from 'axios'

const axiosInterceptor = axios.create();

axiosInterceptor.interceptors.request.use((config) => {
  const jwtToken = document.cookie
    .split('; ')
    .find((row) => row.startsWith('jwt='))
    ?.split('=')[1]
  
  if (jwtToken) {
    config.headers['Authorization'] = `Bearer ${jwtToken}`
  }

  config.withCredentials = true

  return config
});

export default axiosInterceptor
