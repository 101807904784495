import logo from '../assets/fs-logo.png'
import '../styles/footer.scss'

function Footer(){ 
    return(
        <footer>
            <img src={logo} alt="Foresight Logo" />
            <p>The lands on which Foresight are the traditional, ancestral, 
                and unceded terroitories of the First Nation, Inuit, and  Métis peoples.
            </p>
            <span> 
                © 2023 Foresight CAC. All rights reserved.
            </span>
        </footer>
    )
}

export default Footer
